<template>
  <div class="w-full md:w-10/12 overflow-x-auto">
    <ul
      class="bg-white p-4 text-primary text-left font-proximaMedium rounded-sm flex items-center"
    >
      <li class="w-3/12">Description</li>
      <li class="w-1/12 text-center">Date</li>
      <li class="w-1/12 text-center">Size</li>
      <li class="w-1/12 text-center">Type</li>
      <li class="w-3/12 text-center">File Owner</li>
      <li class="w-3/12 text-left">Sharing</li>
    </ul>
    <ul
      v-for="(data, i) in files"
      :key="i"
      class="py-6 border-b border-primary text-sm font-proximaLight flex items-center justify-between"
    >
      <li class="w-3/12 px-2 flex items-center justify-between text-primary">
        {{ data.description }}
      </li>
      <li class="w-1/12 text-center">
        {{ formatDate(data.date_exam) }}
      </li>
      <li class="w-1/12 ml-4 text-center">{{ data.size ? data.size : "-" }}</li>
      <li class="w-1/12 text-center">{{ data.type ? data.type : "-" }}</li>
      <li class="w-3/12" v-if="data.file_owner">
        {{ data.file_owner }}
      </li>
      <li v-else class="w-3/12">You</li>
      <li class="w-3/12 flex flex-col" v-if="data.fileownerEmail">
        <span>{{ data.fileownerName }}</span>
        <span class="text-xs">{{ data.fileownerEmail }}</span>
      </li>
      <li
        class="w-3/12 flex items-center justify-center"
        v-if="data.fileownerPic"
      >
        <img
          class="pr-1 rounded-full"
          :src="require('@/assets/images/' + data.fileownerPic + '.png')"
          :alt="data.fileownerPic"
        />
        {{ data.fileownerName }}
      </li>
      <li class="w-3/12 flex items-center relative">
        {{ data.is_public ? "public" : "private" }}
        <RadioOnoffBorder
          :val="data"
          :checked="data.is_public"
          @inputVal="getInputVal"
        />
        <TrashIcon
          v-if="data.file_owner == 'You'"
          iconColor="#0AC0CB"
          bgColor="#fff"
          width="26"
          height="26"
          class="cursor-pointer absolute right-4 -top-1"
          @click.native="deleteFiles(data)"
        />
      </li>
    </ul>

    <AddIcon
      @click.native="addFileModals = true"
      class="mt-8 cursor-pointer"
      width="62"
      height="62"
    />

    <!-- add files modals  -->
    <ModalsConfirm :show="addFileModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="addFileModals = false"
        >
          ✖
        </div>
      </div>
      <img
        slot="actionQuestion"
        src="@/assets/images/addfiles-icon.png"
        alt="media-icon"
        class="m-auto"
      />
      <div
        slot="actionQuestion"
        class="w-full flex flex-col justify-center items-center"
      >
        <h1 class="mt-4 text-2xl text-subheaderText">Add File</h1>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Description:</span>
          <input
            type="text"
            placeholder="Enter name of disease"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="description"
          />
        </div>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText"
            >Diagnosing Clinic (optional):</span
          >
          <input
            type="text"
            placeholder="Enter name of diagnosing clinic"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="diagnosingClinic"
          />
        </div>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText"
            >Clinic's Email Address (optional):</span
          >
          <input
            type="text"
            placeholder="Enter clinic's email address"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="clinicEmail"
          />
        </div>
        <div class="mt-8 w-10/12 p-4 bg-white border rounded-md">
          <div class="flex flex-col text-left">
            <span class="mb-1 text-subheaderText"
              >Exam / Diagnosis Date (optional)</span
            >
            <date-picker
              type="date"
              format="YYYY-MM-DD"
              v-model="diagnoseDate"
              placeholder="Select date"
              class="w-full my-1 text-sm"
            ></date-picker>
          </div>
        </div>
        <div class="mt-8 w-10/12">
          <InputRadio
            title="Privacy Settings"
            opt1="Private"
            opt2="Public"
            @input="picked"
          />
        </div>
      </div>
      <div
        slot="actionText"
        class="w-10/12 mx-auto pb-6 flex justify-center items-center"
      >
        <BorderedButton
          class="mr-1"
          marginT="mt-2"
          title="Cancel"
          paddingX="px-6"
          width="w-6/12"
          @click.native="addFileModals = false"
        />
        <SubmitButton
          class="ml-1"
          marginT="mt-2"
          title="Continue"
          background="bg-success"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openChooseFiles"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- add files modals end -->

    <!-- choose files modals -->
    <ModalsConfirm :show="chooseFileModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="chooseFileModals = false"
        >
          ✖
        </div>
      </div>
      <img
        slot="actionQuestion"
        src="@/assets/images/media-icon.png"
        alt="media-icon"
        class="m-auto"
      />
      <div slot="actionQuestion">
        <h1 class="mt-4 font-bold text-2xl">Choose a File</h1>
        <p class="mt-4 font-proximaLight text-sm text-subheaderText">
          Medical files are supported, in addition to image and PDF format.
          <br /><br />
          You may take a new photo or upload an existing file from your device
          (max 35MB).
        </p>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <IconButton
          component="PaperclipIcon"
          iconColor="#fff"
          title="Upload File"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="mr-2 py-4"
          @click.native="openFiles()"
        />
        <IconButton
          component="CameraIcon"
          iconColor="#fff"
          title="Take Photo"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="ml-2"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- choose files modals end -->

    <!-- preview files modals -->
    <ModalsConfirm :show="pereviewFileShareModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-5 -right-10 text-lg font-proximaSemibold cursor-pointer"
          @click="closePreviewFileModals"
        >
          ✖
        </div>
      </div>
      <div slot="actionQuestion">
        <img :src="imgUrl" :alt="imgUrl" />
        <h1 class="my-4 text-2xl">Attach Photo?</h1>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <BorderedButton
          class="mr-1"
          marginT="mt-2"
          title="Retake"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openFiles()"
        />
        <SubmitButton
          class="ml-1"
          marginT="mt-2"
          title="Confirm"
          background="bg-success"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openConfirmFileModals"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- preview files modals end -->

    <!-- add files modals  -->
    <ModalsConfirm :show="confirmFileShareModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="closeConfirmFileModals"
        >
          ✖
        </div>
      </div>
      <img
        slot="actionQuestion"
        src="@/assets/images/addfiles-icon.png"
        alt="media-icon"
        class="m-auto"
      />
      <div
        slot="actionQuestion"
        class="w-full flex flex-col justify-center items-center"
      >
        <h1 class="mt-4 text-2xl text-subheaderText">Confirm Add File</h1>
        <FormError :errors="errorForm" />

        <div class="w-10/12 mt-5 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Description:</span>
          <input
            type="text"
            placeholder="Enter name of disease"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="description"
          />
          <FormError :errors="errors.description" />
        </div>
        <div class="w-10/12 mt-5 text-left flex flex-col">
          <span class="mb-2 text-subheaderText"
            >Diagnosing Clinic (optional):</span
          >
          <input
            type="text"
            placeholder="Enter name of diagnosing clinic"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="diagnosingClinic"
          />
        </div>
        <div class="w-10/12 mt-5 text-left flex flex-col">
          <span class="mb-2 text-subheaderText"
            >Clinic's Email Address (optional):</span
          >
          <input
            type="text"
            placeholder="Enter clinic's email address"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="clinicEmail"
          />
        </div>
        <div class="mt-7 w-10/12 p-4 bg-white border rounded-md">
          <div class="flex flex-col text-left">
            <span class="mb-1 text-subheaderText"
              >Exam / Diagnosis Date (optional)</span
            >
            <date-picker
              type="date"
              format="YYYY-MM-DD"
              v-model="diagnoseDate"
              placeholder="Select date"
              class="w-full my-1 text-sm"
            ></date-picker>
          </div>
        </div>
        <div class="mt-3 flex items-center" v-if="uploadedFileName">
          <span class="mr-2 text-primary">{{ uploadedFileName }}</span>
          <img
            src="@/assets/images/close-icon.png"
            alt="close-icon"
            class="cursor-pointer"
            @click="removeImg"
          />
        </div>
        <div class="mt-7 w-10/12">
          <InputRadio
            title="Privacy Settings"
            opt1="Private"
            opt2="Public"
            :val="privacy"
          />
        </div>
      </div>
      <div
        slot="actionText"
        class="w-10/12 mx-auto pb-6 flex justify-center items-center"
      >
        <BorderedButton
          class="mr-1"
          marginT="mt-2"
          title="Cancel"
          paddingX="px-6"
          width="w-6/12"
          @click.native="cancelConfirmFile"
        />
        <SubmitButton
          class="ml-1"
          marginT="mt-2"
          title="Continue"
          background="bg-success"
          paddingX="px-6"
          width="w-6/12"
          @click.native="submitAddFile"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- add files modals end -->
  </div>
</template>

<script>
import TrashIcon from "../../icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import IconButton from "../../buttons/IconButton.vue";
import FormError from "../../warning/FormError.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import InputRadio from "../../forms/InputRadio.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["pet"],
  components: {
    TrashIcon,
    AddIcon,
    RadioOnoffBorder,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    IconButton,
    DatePicker,
    InputRadio,
    FormError,
  },
  data() {
    return {
      addFileModals: false,
      chooseFileModals: false,
      description: "",
      diagnosingClinic: "",
      clinicEmail: "",
      diagnoseDate: "",
      privacy: 1,
      imgUrl: "",
      uploadedFileName: "",
      errors: {},
      fileData: null,
    };
  },
  computed: {
    ...mapGetters({
      files: "sharefile/getFiles",
    }),
    confirmFileShareModals() {
      return this.$store.state.confirmFileShareModalsState;
    },
    pereviewFileShareModals() {
      return this.$store.state.pereviewFileShareModalsState;
    },
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  created() {
    this.getFiles(this.pet.petID);
  },
  methods: {
    ...mapActions({
      getFiles: "sharefile/getFiles",
      addFile: "sharefile/addFile",
      deleteFile: "sharefile/deleteFile",
      updateFile: "sharefile/updateFile",
    }),
    openChooseFiles() {
      this.addFileModals = false;
      this.chooseFileModals = true;
    },

    async getInputVal(val, check) {
      //this.toggleIncognito = !this.toggleIncognito;
      console.log(val, check);
      val.is_public = check;
      this.updateFile(val);
      /*  const data = {
        check: check,
        pet: this.pet,
      };

      this.updatePrivateMode(data); */
    },

    openConfirmFileModals() {
      this.$store.commit("errorForm", "");
      this.errors = {};
      this.$store.commit("pereviewFileShareModals", false);
      this.$store.commit("confirmFileShareModals", true);
    },
    closeConfirmFileModals() {
      this.$store.commit("confirmFileShareModals", false);
    },
    closePreviewFileModals() {
      this.$store.commit("pereviewFileShareModals", false);
    },
    picked(e) {
      this.privacy = e;
    },
    cancelConfirmFile() {
      this.$store.commit("confirmFileShareModals", false);
      this.description = "";
      this.diagnosingClinic = "";
      this.clinicEmail = "";
      this.diagnoseDate = "";
      this.imgUrl = "";
    },
    removeImg() {
      this.uploadedFileName = "";
      this.imgUrl = "";
    },
    openFiles() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileName(e) {
      this.uploadedFileName = e.target.files[0].name;
      const file = e.target.files[0];
      this.fileData = file;
      this.imgUrl = URL.createObjectURL(file);
      this.chooseFileModals = false;
      this.$store.commit("pereviewFileShareModals", true);
    },
    validate() {
      const errors = {};

      if (this.description.trim() === "")
        errors.description =
          this.$t("global_description") + " " + this.$t("global_is_required");

      return Object.keys(errors).length === 0 ? null : errors;
    },

    formatDate(d) {
      return moment(d.substr(0, 10)).format("DD.MM.YYYY");
    },

    deleteFiles(data) {
      console.log(data);
      this.deleteFile(data.id);
    },

    submitAddFile() {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      const data = {
        description: this.description,
        diagnosis_clinic: this.diagnosingClinic,
        clinic_email_address: this.clinicEmail,
        date_exam: moment(this.diagnoseDate).format("YYYY-MM-DD"),
        is_public: this.privacy == 2 ? 1 : 0,
        uploaded_file: this.fileData,
        userEmailAddress: localStorage.getItem("email"),
        petID: this.pet.petID,
      };

      this.addFile(data);
      console.log(data);
    },
  },
};
</script>

<style>
.mx-datepicker-popup {
  z-index: 9999;
}
</style>