<template>
  <div class="my-6">
    <PetownerSection />
  </div>
</template>

<script>
import PetownerSection from "../components/medicalrecordsharedfiles/petowner/PetownerSection.vue";

export default {
  components: {
    PetownerSection,
  },
};
</script>