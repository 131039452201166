<template>
  <div class="w-full md:w-10/12 overflow-x-auto">
    <ul
      class="bg-white p-4 text-primary text-left font-proximaMedium rounded-sm flex items-center"
    >
      <li class="w-2/12">Description</li>
      <li class="w-1/12 text-center">Exam Date</li>
      <li class="w-1/12 text-center">Size</li>
      <li class="w-1/12 text-center">Type</li>
      <li class="w-2/12 text-center">File Owner</li>
      <li class="w-2/12 text-center">Label</li>
      <li class="w-3/12 text-left">Sharing</li>
    </ul>
    <ul
      v-for="(data, i) in files"
      :key="i"
      class="py-6 border-b border-primary text-sm font-proximaLight flex items-center justify-between"
    >
      <li class="w-2/12 px-2 flex items-center justify-between text-primary">
        {{ data.description }}
      </li>
      <li class="w-1/12 text-center">
        {{ formatDate(data.date_exam) }}
      </li>
      <li class="w-1/12 text-center">{{ data.size }}</li>
      <li class="w-1/12 text-center">{{ data.type }}</li>
      <li class="w-2/12" v-if="data.file_owner">
        {{ data.file_owner }}
      </li>
      <li class="w-2/12 flex flex-col" v-if="data.fileownerEmail">
        <span>{{ data.fileownerName }}</span>
        <span class="text-xs">{{ data.fileownerEmail }}</span>
      </li>
      <li
        class="w-2/12 flex items-center justify-center"
        v-if="data.fileownerPic"
      >
        <img
          class="pr-1 rounded-full"
          :src="require('@/assets/images/' + data.fileownerPic + '.png')"
          :alt="data.fileownerPic"
        />
        {{ data.fileownerName }}
      </li>
      <li class="w-2/12 text-center">
        {{ data.labels ? data.labels.map((e) => e.code).toString() : "-" }}
      </li>
      <li class="w-3/12 flex items-center relative">
        {{ data.is_public }}
        <RadioOnoffBorder :checked="data.is_public" />
        <TrashIcon
          v-if="data.file_owner == 'You'"
          iconColor="#0AC0CB"
          bgColor="#fff"
          width="26"
          height="26"
          class="cursor-pointer absolute right-4 -top-1"
          @click.native="deleteFiles(data)"
        />
      </li>
    </ul>

    <AddIcon
      @click.native="addFileModals = true"
      class="mt-8 cursor-pointer"
      width="62"
      height="62"
    />

    <!-- add files modals  -->
    <ModalsConfirm :show="addFileModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="addFileModals = false"
        >
          ✖
        </div>
      </div>
      <img
        slot="actionQuestion"
        src="@/assets/images/addfiles-icon.png"
        alt="media-icon"
        class="m-auto h-20"
      />
      <div
        slot="actionQuestion"
        class="w-full flex flex-col justify-center items-center"
      >
        <h1 class="mt-4 text-2xl text-subheaderText">Add File</h1>
        <div class="w-10/12 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Description:</span>
          <input
            type="text"
            placeholder="Enter name of disease"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="description"
          />
        </div>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText"
            >Diagnosing Clinic (optional):</span
          >
          <input
            type="text"
            placeholder="Enter name of diagnosing clinic"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="diagnosingClinic"
          />
        </div>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText"
            >Clinic's Email Address (optional):</span
          >
          <input
            type="text"
            placeholder="Enter clinic's email address"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="clinicEmail"
          />
        </div>
        <div class="mt-8 w-10/12 p-4 bg-white border rounded-md">
          <div class="flex flex-col text-left">
            <span class="mb-1 text-subheaderText"
              >Exam / Diagnosis Date (optional)</span
            >
            <date-picker
              type="date"
              format="YYYY-MM-DD"
              v-model="diagnoseDate"
              placeholder="Select date"
              class="w-full my-1 text-sm"
            ></date-picker>
          </div>
        </div>
        <div class="mt-8 w-10/12">
          <InputRadio
            title="Privacy Settings"
            opt1="Private"
            opt2="Public"
            @input="pickedPrivacy"
          />
        </div>
        <div class="mt-8 w-10/12">
          <InputRadio
            title="Official Results (add label)"
            opt1="Yes"
            opt2="No"
            @input="pickedLabel"
          />
        </div>
      </div>
      <div
        slot="actionText"
        class="w-10/12 mx-auto pb-6 flex justify-center items-center"
      >
        <BorderedButton
          class="mr-1"
          marginT="mt-2"
          title="Cancel"
          paddingX="px-6"
          width="w-6/12"
          @click.native="addFileModals = false"
        />
        <SubmitButton
          class="ml-1"
          marginT="mt-2"
          title="Continue"
          background="bg-success"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openChooseFiles"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- add files modals end -->

    <!-- choose files modals -->
    <ModalsConfirm :show="chooseFileModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="chooseFileModals = false"
        >
          ✖
        </div>
      </div>
      <img
        slot="actionQuestion"
        src="@/assets/images/media-icon.png"
        alt="media-icon"
        class="m-auto"
      />
      <div slot="actionQuestion">
        <h1 class="mt-4 font-bold text-2xl">Choose a File</h1>
        <p class="mt-4 font-proximaLight text-sm text-subheaderText">
          Medical files are supported, in addition to image and PDF format.
          <br /><br />
          You may take a new photo or upload an existing file from your device
          (max 35MB).
        </p>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <IconButton
          component="PaperclipIcon"
          iconColor="#fff"
          title="Upload File"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="mr-2 py-4"
          @click.native="openFiles()"
        />
        <IconButton
          component="CameraIcon"
          iconColor="#fff"
          title="Take Photo"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="ml-2"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- choose files modals end -->

    <!-- preview files modals -->
    <ModalsConfirm :show="pereviewFileShareModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-5 -right-10 text-2xl font-proximaSemibold cursor-pointer"
          @click="closePreviewFileModals"
        >
          ✖
        </div>
      </div>
      <div slot="actionQuestion">
        <img :src="imgUrl" :alt="imgUrl" />
        <h1 class="my-4 text-2xl">Attach Photo?</h1>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <BorderedButton
          class="mr-1"
          marginT="mt-2"
          title="Retake"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openFiles()"
        />
        <SubmitButton
          class="ml-1"
          marginT="mt-2"
          title="Confirm"
          background="bg-success"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openLabelResultModals"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- preview files modals end -->

    <!-- label official result modals -->
    <ModalsConfirm :show="labelResultModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-5 -right-10 text-2xl font-proximaSemibold cursor-pointer"
          @click="labelResultModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionQuestion">
        <h1 class="my-4 text-2xl text-subheaderText">Label Official Results</h1>
        <p class="italic font-proximaLight text-sm text-left">
          You may add labels to the exam results that you are uploading. Please
          keep in mind that the diseases marked in orange cannot be definitively
          tested for and thus results will “expire” after 12 months.
        </p>
      </div>
      <div slot="actionText" class="pb-6 flex flex-wrap">
        <FiveOptionsForm
          name="hip"
          @radioVal="getHipVal"
          @checkedVal="checkedhipVal"
        />
        <FourOptionsForm
          class="mt-5"
          label="Elbow Dysplasia (ED)"
          title1="0"
          title2="1"
          title3="2"
          title4="3"
          name="elbow"
          @checkedVal="checkedelbowVal"
          @radioVal="getElbowVal"
        />
        <FourOptionsForm
          class="mt-5"
          label="PHTVL/PHPV"
          title1="0"
          title2="doubt"
          title3="1"
          title4="2+"
          name="PHTVL"
          @checkedVal="checkedPHTVLVal"
          @radioVal="getPhtvlVal"
        />
        <ThreeOptionsForm
          class="mt-5"
          label="vWD"
          title1="Clear"
          title2="Carrier"
          title3="Affected"
          name="vWD"
          @checkedVal="checkedvWDVal"
          @radioVal="getVwdVal"
        />
        <ThreeOptionsForm
          class="mt-5"
          label="Dilution"
          title1="DD"
          title2="Dd"
          title3="dd"
          name="Dilution"
          @radioVal="getDilutionVal"
          @checkedVal="checkedDilutionVal"
        />
        <ThreeOptionsForm
          class="mt-5"
          label="DCM1"
          title1="-/-"
          title2="+/-"
          title3="+/+"
          name="DCM1"
          @radioVal="getDcm1Val"
          @checkedVal="checkedDCM1Val"
        />
        <ThreeOptionsForm
          class="mt-5"
          label="DCM2"
          title1="-/-"
          title2="+/-"
          title3="+/+"
          name="DCM2"
          @radioVal="getDcm2Val"
          @checkedVal="checkedDCM2Val"
        />
        <ThreeOptionsForm
          class="mt-5"
          label="DINGS"
          title1="Clear"
          title2="Carrier"
          title3="Affected"
          name="DINGS"
          @radioVal="getDingsVal"
          @checkedVal="checkedDINGSVal"
        />
        <ThreeOptionsForm
          class="mt-5"
          label="CF5"
          title1="wildtype"
          title2="+/-"
          title3="+/+"
          name="CF5"
          @checkedVal="checkedCF5Val"
          @radioVal="getCF5Val"
        />
        <div class="mt-5 px-5 pb-5 w-full bg-primary-20 rounded-md">
          <ThreeOptionsForm
            class="mt-5"
            label="DCM (Hotler)"
            title1="Normal"
            title2="Equivocal"
            title3="Abnormal"
            name="DCMhotler"
            @radioVal="getDCMhotlerVal"
            @checkedVal="checkedDCMhotlerVal"
          />
          <ThreeOptionsForm
            class="mt-5"
            label="DCM (Echo)"
            title1="Normal"
            title2="Equivocal"
            title3="Abnormal"
            name="DCMecho"
            @radioVal="getDCMechoVal"
            @checkedVal="checkedDCMechoVal"
          />
          <ThreeOptionsForm
            class="mt-5"
            label="Thyroid"
            title1="Normal"
            title2="Equivocal"
            title3="Abnormal"
            name="Thyroid"
            @radioVal="getThyroidVal"
            @checkedVal="checkedThyroidVal"
          />
        </div>
        <div class="mt-4 w-full flex">
          <BorderedButton
            class="mr-1"
            marginT="mt-2"
            title="Cancel"
            paddingX="px-6"
            width="w-6/12"
            @click.native="labelResultModals = false"
          />
          <SubmitButton
            class="ml-1"
            marginT="mt-2"
            title="Confirm"
            background="bg-success"
            paddingX="px-6"
            width="w-6/12"
            @click.native="openConfirmFileModals"
          />
        </div>
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- label official result modals end -->

    <!-- add files modals  -->
    <ModalsConfirm :show="confirmFileShareModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="closeConfirmFileModals"
        >
          ✖
        </div>
      </div>
      <!-- <img
        slot="actionQuestion"
        src="@/assets/images/addfiles-icon.png"
        alt="media-icon"
        class="m-auto"
      /> -->
      <div
        slot="actionQuestion"
        class="w-full flex flex-col justify-center items-center"
      >
        <h1 class="mt-4 text-2xl text-subheaderText">Confirm Add File</h1>
        <FormError :errors="errorForm" />
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText">Description:</span>

          <input
            type="text"
            placeholder="Enter name of disease"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="description"
          />
          <FormError :errors="errors.description" />
        </div>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText"
            >Diagnosing Clinic (optional):</span
          >
          <input
            type="text"
            placeholder="Enter name of diagnosing clinic"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="diagnosingClinic"
          />
        </div>
        <div class="w-10/12 mt-6 text-left flex flex-col">
          <span class="mb-2 text-subheaderText"
            >Clinic's Email Address (optional):</span
          >
          <input
            type="text"
            placeholder="Enter clinic's email address"
            class="font-proximaLight text-sm p-4 bg-lightGrey20 rounded-md"
            v-model="clinicEmail"
          />
        </div>
        <div class="mt-6 w-10/12 text-left flex flex-col">
          <span class="mb-1 text-subheaderText"
            >Exam / Diagnosis Date (optional)</span
          >
          <date-picker
            type="date"
            format="YYYY-MM-DD"
            v-model="diagnoseDate"
            placeholder="Select date"
            class="w-full my-1 text-sm"
          ></date-picker>
        </div>
        <div class="mt-4 flex items-center" v-if="uploadedFileName">
          <span class="mr-2 text-primary">{{ uploadedFileName }}</span>
          <img
            src="@/assets/images/close-icon.png"
            alt="close-icon"
            class="cursor-pointer"
            @click="removeImg"
          />
        </div>
        <div class="mt-6 w-full text-left">
          <span class="mb-1 text-subheaderText">Labels:</span>
          <div
            class="w-full h-32 p-4 flex flex-wrap items-start justify-start border border-primary rounded-md bg-lightGrey20 overflow-auto"
          >
            <div
              v-for="(data, i) in arrLabels"
              :key="i"
              class="h-fit mx-2 my-1 px-4 py-2 relative rounded-md bg-primary text-white"
            >
              <TrashIcon
                class="absolute -top-2 -right-2 cursor-pointer"
                iconColor="#0AC0CB"
                bgColor="#fff"
                @click.native="removeLabel(i)"
              />
              <span>{{ data }}</span>
            </div>
          </div>
        </div>
        <div class="mt-8 w-10/12">
          <InputRadio
            title="Privacy Settings"
            opt1="Private"
            opt2="Public"
            :val="privacy"
          />
        </div>
      </div>
      <div
        slot="actionText"
        class="w-10/12 mx-auto pb-6 flex justify-center items-center"
      >
        <BorderedButton
          class="mr-1"
          marginT="mt-2"
          title="Cancel"
          paddingX="px-6"
          width="w-6/12"
          @click.native="cancelConfirmFile"
        />
        <SubmitButton
          class="ml-1"
          marginT="mt-2"
          title="Continue"
          background="bg-success"
          paddingX="px-6"
          width="w-6/12"
          @click.native="submitAddFile"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- add files modals end -->
  </div>
</template>

<script>
import TrashIcon from "../../icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import IconButton from "../../buttons/IconButton.vue";
import FormError from "../../warning/FormError.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import InputRadio from "../../forms/InputRadio.vue";
import FiveOptionsForm from "./forms/FiveOptionsForm.vue";
import FourOptionsForm from "./forms/FourOptionsForm.vue";
import ThreeOptionsForm from "./forms/ThreeOptionsForm.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { examresult } from "../../../methods/petMethods";

export default {
  props: ["pet"],
  components: {
    TrashIcon,
    AddIcon,
    RadioOnoffBorder,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    IconButton,
    DatePicker,
    InputRadio,
    FiveOptionsForm,
    FourOptionsForm,
    ThreeOptionsForm,
    FormError,
  },
  data() {
    return {
      addFileModals: false,
      chooseFileModals: false,
      labelResultModals: false,
      description: "",
      diagnosingClinic: "",
      clinicEmail: "",
      diagnoseDate: "",
      privacy: 1,
      label: 1,
      imgUrl: "",
      uploadedFileName: "",
      fileData: null,
      thyroid: "",
      dcmecho: "",
      dcmhotler: "",
      cf5: "",
      dings: "",
      dcm2: "",
      dcm1: "",
      dilution: "",
      vwd: "",
      phtvl: "",
      elbowdys: "",
      hipdys: "",
      arrLabels: [],
      exam_results: [],
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      files: "sharefile/getFiles",
    }),
    getNameExamresult(item) {
      return examresult(item);
    },
    confirmFileShareModals() {
      return this.$store.state.confirmFileShareModalsState;
    },
    pereviewFileShareModals() {
      return this.$store.state.pereviewFileShareModalsState;
    },
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  created() {
    this.getFiles(this.pet.petID);
  },
  methods: {
    ...mapActions({
      getFiles: "sharefile/getFiles",
      addFile: "sharefile/addFileBreeder",
      deleteFile: "sharefile/deleteFile",
      updateFile: "sharefile/updateFile",
    }),
    openChooseFiles() {
      this.addFileModals = false;
      this.chooseFileModals = true;
    },
    openLabelResultModals() {
      this.closePreviewFileModals();
      if (this.label == 2) {
        this.$store.commit("errorForm", "");
        this.errors = {};
        this.$store.commit("confirmFileShareModals", true);
      } else {
        this.labelResultModals = true;
      }
    },
    openConfirmFileModals() {
      this.$store.commit("errorForm", "");
      this.errors = {};
      this.labelResultModals = false;
      //this.$store.commit("pereviewFileShareModals", false);
      this.$store.commit("confirmFileShareModals", true);

      const listLabels =
        this.dcmhotler +
        "," +
        this.dcmecho +
        "," +
        this.thyroid +
        "," +
        this.cf5 +
        "," +
        this.dings +
        "," +
        this.dcm2 +
        "," +
        this.dcm1 +
        "," +
        this.dilution +
        "," +
        this.vwd +
        "," +
        this.phtvl +
        "," +
        this.elbowdys +
        "," +
        this.hipdys;

      const arr = listLabels.split(",");
      const filteredArr = arr.filter((item) => item.trim() !== "");
      this.arrLabels = filteredArr;
    },
    closeConfirmFileModals() {
      this.$store.commit("confirmFileShareModals", false);
    },
    closePreviewFileModals() {
      this.$store.commit("pereviewFileShareModals", false);
    },

    removeLabel(i) {
      this.arrLabels.splice(i, 1);
    },
    pickedPrivacy(e) {
      this.privacy = e;
    },
    pickedLabel(e) {
      this.label = e;
      console.log(e);
    },
    cancelConfirmFile() {
      this.$store.commit("confirmFileShareModals", false);
      this.description = "";
      this.diagnosingClinic = "";
      this.clinicEmail = "";
      this.diagnoseDate = "";
      this.imgUrl = "";
      this.arrLabels = [];
    },
    removeImg() {
      this.uploadedFileName = "";
      this.imgUrl = "";
    },
    openFiles() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileName(e) {
      this.uploadedFileName = e.target.files[0].name;
      const file = e.target.files[0];
      this.fileData = file;
      this.imgUrl = URL.createObjectURL(file);
      this.chooseFileModals = false;
      this.$store.commit("pereviewFileShareModals", true);
    },
    getDCMechoVal(i) {
      this.dcmecho = "Echo - " + i;
    },
    checkedDCMechoVal(i) {
      this.dcmecho = i ? "Echo - Normal" : "";
      console.log(this.dcmecho, i);
    },

    getCF5Val(i) {
      this.cf5 = "CF5 " + i;
      console.log(this.cf5, i);
    },
    checkedCF5Val(i) {
      this.cf5 = i ? "CF5 - wildtype" : "";
      console.log(this.cf5, i);
    },
    getDingsVal(i) {
      console.log("DINGS - " + i);
      this.dings = "DINGS - " + i;
    },
    checkedDINGSVal(i) {
      this.dings = i ? "DINGS - Clear" : "";
      console.log(this.dings, i);
    },

    getDCMhotlerVal(i) {
      this.dcmhotler = "Holter - " + i;
      console.log("Holter - " + i);
    },
    checkedDCMhotlerVal(i) {
      this.dcmhotler = i ? "Holter - Abnormal" : "";
      console.log(this.dcmhotler, i);
    },

    getThyroidVal(i) {
      console.log("Thyroid - " + i);
      this.thyroid = "Thyroid - " + i;
    },

    checkedThyroidVal(i) {
      this.thyroid = i ? "Thyroid - Normal" : "";
      console.log(this.thyroid, i);
    },

    getDcm2Val(i) {
      console.log("DCM2 " + i);
      this.dcm2 = "DCM2 " + i;
    },
    checkedDCM2Val(i) {
      this.dcm2 = i ? "DCM2 -/-" : "";
      console.log(this.dcm2, i);
    },
    getDcm1Val(i) {
      console.log("DCM1 " + i);
      this.dcm1 = "DCM1 " + i;
    },
    checkedDCM1Val(i) {
      this.dcm1 = i ? "DCM1 -/-" : "";
      console.log(this.dcm1, i);
    },
    getDilutionVal(i) {
      console.log("Dilution - " + i);
      this.dilution = "Dilution - " + i;
    },
    checkedDilutionVal(i) {
      this.dilution = i ? "Dilution - DD" : "";
      console.log(this.dilution, i);
    },
    getVwdVal(i) {
      console.log("VWD - " + i);
      this.vwd = "VWD - " + i;
    },
    checkedvWDVal(i) {
      this.vwd = i ? "VWD - Clear" : "";
      console.log(this.vwd, i);
    },
    checkedPHTVLVal(i) {
      this.phtvl = i ? "PHTVL/PHPV - 0" : "";
      console.log(this.phtvl, i);
    },
    getPhtvlVal(i) {
      console.log("PHTVL/PHPV - " + i);
      this.phtvl = "PHTVL/PHPV - " + i;
    },
    getElbowVal(i) {
      console.log("ED - " + i);
      this.elbowdys = "ED - " + i;
    },
    checkedelbowVal(i) {
      this.elbowdys = i ? "ED - 0" : "";
      console.log(this.elbowdys, i);
    },
    getHipVal(i) {
      this.hipdys = "HD - " + i;
      console.log("HD - " + i);
    },
    checkedhipVal(i) {
      this.hipdys = i ? "HD - A" : "";
      console.log(this.hipdys, i);
    },
    validate() {
      const errors = {};

      if (this.description.trim() === "")
        errors.description =
          this.$t("global_description") + " " + this.$t("global_is_required");

      return Object.keys(errors).length === 0 ? null : errors;
    },

    formatDate(d) {
      return moment(d.substr(0, 10)).format("DD.MM.YYYY");
    },

    deleteFiles(data) {
      console.log(data);
      this.deleteFile(data.id);
    },

    submitAddFile() {
      //console.log(arrLabels);
      const exam = [];

      this.arrLabels.map((item) => exam.push(examresult(item)));
      console.log(exam);
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      const data = {
        description: this.description,
        diagnosis_clinic: this.diagnosingClinic,
        clinic_email_address: this.clinicEmail,
        date_exam: this.diagnoseDate
          ? moment(this.diagnoseDate).format("YYYY-MM-DD")
          : null,
        is_public: this.privacy == 2 ? 1 : 0,
        is_official: this.label == 2 ? 0 : 1,
        exam_results: exam,
        uploaded_file: this.fileData,
        userEmailAddress: localStorage.getItem("email"),
        petID: this.pet.petID,
      };

      this.addFile(data);
      console.log(data);
    },
  },
};
</script>

<style>
.mx-datepicker {
  width: 100%;
}
.mx-input {
  background: rgba(156, 163, 175, 0.2);
  border: none;
}
.mx-datepicker-popup {
  z-index: 9999;
}
</style>