<template>
  <div class="w-full flex items-end justify-start">
    <label class="w-6/12 text-left text-proximaLight text-sm">
      <input
        type="checkbox"
        v-model="isChecked"
        class="peer-checked:bg-primary"
        @change="checkthis"
      />
      Hip Dysplasia (HD)
    </label>
    <div class="w-6/12 flex items-center justify-center">
      <InputRadio
        class="w-2/12"
        :checked="radioChecked"
        title="A"
        :name="name"
        @inputVal="getRadioVal"
      />
      <InputRadio
        class="w-2/12"
        title="B"
        :name="name"
        @inputVal="getRadioVal"
      />
      <InputRadio
        class="w-2/12"
        title="C"
        :name="name"
        @inputVal="getRadioVal"
      />
      <InputRadio
        class="w-2/12"
        title="D"
        :name="name"
        @inputVal="getRadioVal"
      />
      <InputRadio
        class="w-2/12"
        title="E"
        :name="name"
        @inputVal="getRadioVal"
      />
    </div>
  </div>
</template>

<script>
import InputRadio from "./InputRadio.vue";

export default {
  props: ["name"],
  components: {
    InputRadio,
  },
  data() {
    return {
      isChecked: false,
      radioChecked: false,
    };
  },
  methods: {
    checkthis() {
      this.radioChecked = !this.radioChecked;
      this.$emit("checkedVal", this.radioChecked);
    },
    getRadioVal(i, e) {
      let mergedString = e;
      this.$emit("radioVal", mergedString);
    },
  },
};
</script>

<style>
</style>