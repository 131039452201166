<template>
  <div class="flex flex-col items-center justify-between">
    <span class="text-sm font-proximaMedium">{{ title }}</span>
    <label class="container font-proximaLight capitalize">
      <input
        type="radio"
        @change="valChange(name, title)"
        :checked="checked"
        :name="name"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: { title: String, id: Number, checked: Boolean, name: String },

  methods: {
    valChange(i, e) {
      // console.log(e);
      this.$emit("inputVal", i, e);
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  /* padding-left: 50px; */
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
</style>