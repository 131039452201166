<template>
  <div class="my-6">
    <BreederSection />
  </div>
</template>

<script>
import BreederSection from "../components/medicalrecordsharedfiles/breeder/BreederSection.vue";

export default {
  components: {
    BreederSection,
  },
};
</script>